// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-data-pages-index-jsx": () => import("./../src/data/pages/index.jsx" /* webpackChunkName: "component---src-data-pages-index-jsx" */),
  "component---src-data-pages-404-index-tsx": () => import("./../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-about-index-jsx": () => import("./../src/data/pages/about/index.jsx" /* webpackChunkName: "component---src-data-pages-about-index-jsx" */),
  "component---src-data-pages-about-migraine-index-jsx": () => import("./../src/data/pages/about-migraine/index.jsx" /* webpackChunkName: "component---src-data-pages-about-migraine-index-jsx" */),
  "component---src-data-pages-causes-index-jsx": () => import("./../src/data/pages/causes/index.jsx" /* webpackChunkName: "component---src-data-pages-causes-index-jsx" */),
  "component---src-data-pages-contact-us-index-jsx": () => import("./../src/data/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-data-pages-contact-us-index-jsx" */),
  "component---src-data-pages-cookie-policy-index-jsx": () => import("./../src/data/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-cookie-policy-index-jsx" */),
  "component---src-data-pages-legal-notice-index-jsx": () => import("./../src/data/pages/legal-notice/index.jsx" /* webpackChunkName: "component---src-data-pages-legal-notice-index-jsx" */),
  "component---src-data-pages-home-index-jsx": () => import("./../src/data/pages/home/index.jsx" /* webpackChunkName: "component---src-data-pages-home-index-jsx" */),
  "component---src-data-pages-migraine-causes-index-jsx": () => import("./../src/data/pages/migraine-causes/index.jsx" /* webpackChunkName: "component---src-data-pages-migraine-causes-index-jsx" */),
  "component---src-data-pages-migraine-relief-products-index-jsx": () => import("./../src/data/pages/migraine-relief-products/index.jsx" /* webpackChunkName: "component---src-data-pages-migraine-relief-products-index-jsx" */),
  "component---src-data-pages-migraine-symptoms-index-jsx": () => import("./../src/data/pages/migraine-symptoms/index.jsx" /* webpackChunkName: "component---src-data-pages-migraine-symptoms-index-jsx" */),
  "component---src-data-pages-migraine-tips-index-jsx": () => import("./../src/data/pages/migraine-tips/index.jsx" /* webpackChunkName: "component---src-data-pages-migraine-tips-index-jsx" */),
  "component---src-data-pages-migraine-treatment-and-prevention-index-jsx": () => import("./../src/data/pages/migraine-treatment-and-prevention/index.jsx" /* webpackChunkName: "component---src-data-pages-migraine-treatment-and-prevention-index-jsx" */),
  "component---src-data-pages-migraine-trigger-diary-index-jsx": () => import("./../src/data/pages/migraine-trigger-diary/index.jsx" /* webpackChunkName: "component---src-data-pages-migraine-trigger-diary-index-jsx" */),
  "component---src-data-pages-privacy-policy-index-jsx": () => import("./../src/data/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-data-pages-privacy-policy-index-jsx" */),
  "component---src-data-pages-products-index-jsx": () => import("./../src/data/pages/products/index.jsx" /* webpackChunkName: "component---src-data-pages-products-index-jsx" */),
  "component---src-data-pages-tips-index-jsx": () => import("./../src/data/pages/tips/index.jsx" /* webpackChunkName: "component---src-data-pages-tips-index-jsx" */),
  "component---src-data-pages-trigger-diary-index-jsx": () => import("./../src/data/pages/trigger-diary/index.jsx" /* webpackChunkName: "component---src-data-pages-trigger-diary-index-jsx" */),
  "component---src-data-pages-what-can-you-do-index-jsx": () => import("./../src/data/pages/what-can-you-do/index.jsx" /* webpackChunkName: "component---src-data-pages-what-can-you-do-index-jsx" */),
  "component---src-data-pages-where-to-buy-index-jsx": () => import("./../src/data/pages/where-to-buy/index.jsx" /* webpackChunkName: "component---src-data-pages-where-to-buy-index-jsx" */),
  "component---src-data-pages-sites-migraleve-co-uk-files-pop-up-text-index-jsx": () => import("./../src/data/pages/sites/migraleve.co.uk/files/pop-up-text/index.jsx" /* webpackChunkName: "component---src-data-pages-sites-migraleve-co-uk-files-pop-up-text-index-jsx" */)
}

